import http from '@/http/axios';
import common from './common';

async function loginApi(captcha, verificationTicket, data) {
  const res = await http.post(`/api/PortalUsers/Login?captcha=${captcha}&token=${verificationTicket}`, data);
  return common.haveSuccess(res);
}

async function logOutApi() {
  const res = await http.post(`/Home/logout`);
  return common.haveSuccess(res);
}

export default {
  loginApi,
  logOutApi,
};
